import React from 'react';
import styled from '@emotion/styled';
import { css } from '@emotion/core';
import { Select, MenuItem, Button } from '@material-ui/core';
import { useLanguage } from 'src/components/TextContext';
import usFlag from './images/us-flag.svg';
import spainFlag from './images/spain-flag.svg';

const FlagImg = styled.img`
  width: 2rem;
  object-fit: contain;
  margin-left: 0.5rem;
`;

const Container = styled.div`
  display: flex;
  align-items: center;
`;

export const LanguageSelect = () => {
  const { currentLanguage, setLanguage } = useLanguage();
  const grayCss = css`
    filter: grayscale(1);
  `;
  return (
    <div
      css={css`
        display: flex;
      `}
    >
      <Button
        onClick={() => setLanguage('en')}
        {...(currentLanguage == 'en' ? {} : { css: grayCss })}
      >
        <FlagImg src={usFlag} />
      </Button>
      <Button
        onClick={() => setLanguage('es')}
        {...(currentLanguage == 'es' ? {} : { css: grayCss })}
      >
        <FlagImg src={spainFlag} />
      </Button>
    </div>
  );
};

export default LanguageSelect;
