import React from 'react';
import { css } from '@emotion/core';
import styled from '@emotion/styled';
import { Typography } from '@material-ui/core';
import { COLOR_GREEN_MUTICAO } from 'src/consts';

const SectionHeaderContainer = styled.div`
  display: flex;
  text-transform: uppercase;
  font-weight: bold;
`;

export const SectionHeader = ({ children, ...props }) => {
  const text = children;
  const [first, second, third] = text.split(' ');

  return (
    <SectionHeaderContainer {...props}>
      <Typography
        variant="h4"
        css={css`
          margin: auto;
          font-weight: bold;
          transform: scale(1.5);
        `}
      >
        {!second && first}
        {!third && second && (
          <>
            <span
              css={css`
                color: ${COLOR_GREEN_MUTICAO};
              `}
            >
              {first}
            </span>
            {' '}
            {second}
          </>
        )}
         {third && (
          <>
            <span
              css={css`
                color: ${COLOR_GREEN_MUTICAO};
              `}
            >
              {first}
            </span>
            {' '}
            {second}
            <span
              css={css`
                color: ${COLOR_GREEN_MUTICAO};
              `}
            >
              {' '}
              {third}
            </span>
          </>
        )}
      </Typography>
    </SectionHeaderContainer>
  );
};
