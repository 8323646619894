import { createMuiTheme, ThemeOptions } from '@material-ui/core';
import { green } from '@material-ui/core/colors';
import { COLOR_GREEN_MUTICAO } from 'src/consts';

export const muiThemeOptions: ThemeOptions = {
  typography: {
    fontFamily: ['Alegreya Sans', 'Helvetica', 'Arial', 'sans-serif'],
  },
  palette: {
    primary: { ...green, main: COLOR_GREEN_MUTICAO },
    background: {
      default: '#ffffff',
    },
  },
};

export const muiTheme = createMuiTheme(muiThemeOptions);

export default muiTheme;
